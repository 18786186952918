import React from 'react';

// Components
import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Liberation = () => {
	return (
		<div class='flex flex-col justify-between'>
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<YellowHeader />
			<main class='flex flex-col items-center'>
				{/* Titre */}
				<section class='h-1/5 lg:h-40 md:h-40 w-full flex justify-start relative top-4 mt-16 lg:mt-24 pl-4 box-border'>
					<div class='h-full w-6/12 bg-opacity-75 flex text-start bg-yellow-400 pl-4 items-center'>
						<h2 class='text-xl md:text-4xl opacity-70 xxl:leading-normal'>
							MÉTHODE <br />
							DE LIBÉRATION DES ÉMOTIONS
						</h2>
					</div>
				</section>

				{/* Contenant avec bg */}
				<div class='z-1 w-11/12 p-4 mb-2 lg:w-8/12 bg-tech-backg bg-right-top right-2 lg:right-24 xxl:left-80 top-28 md:top-48 lg:top-56 flex flex-col justify-evenly md:items-center'>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Mon cadre Thérapeutique{' '}
						</h3>
						<br />
						<p class='text-sm md:text-base leading-7 opacity-80'>
							J’utilise le “test musculaire” issu de la kinésiologie. Notre état
							de santé peut être lu dans nos tensions musculaires. Nous pouvons
							instaurer un véritable dialogue avec notre corps en testant la
							réactivité musculaire à différents stimulus. Concrètement,
							j’utilise de légères pressions sur le poignet pendant la séance.
							Cette Technique me permet d’aller à la rencontre du Guide
							Intérieur, cette partie de nous qui connait la source de nos
							difficultés, qui garde en mémoire, tout ce que l’on a vécu de la
							Naissance à l’âge adulte mais aussi notre bagage
							transgénérationnel avec les mémoires de nos ancêtres /notre
							famille.
						</p>
					</section>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Comment se déroule une séance ?
						</h3>
						<br />
						<p class='text-sm md:text-base leading-7 opacity-80'>
							La prise de conscience de la nature du blocage et sa verbalisation
							permettent de le libérer. En remontant à la surface de notre
							cerveau, celui-ci peut alors le digérer et l’évacuer, en libérant
							la charge émotionnelle associée. Avec cette approche et à l’aide
							de ce formidable outil qu’est le test musculaire, nous pouvons
							établir un dialogue avec le corps et entrer en contact avec ces
							mémoires, repérer les sources de blocages principales et
							prioritaires. Notre corps sait précisément ce dont nous avons
							besoin pour retrouver notre équilibre sur les plans mental,
							physique, émotionnel et énergétique. J’utilise alors différents
							outils de rééquilibrage en fonction de ce qui vous correspond le
							mieux.
						</p>
					</section>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Cette séance est pour vous si
						</h3>
						<br />
						<ul class='text-sm md:text-base leading-8 opacity-80'>
							<li>
								• Vous sentez des blocages ou des émotions passées qui vous
								empêchent de vous réaliser.
							</li>
							<li>
								• Vous souhaitez vous libérer des schémas répétitifs négatifs
								Vous voulez rebondir vers de nouveaux projets et renforcer votre
								confiance en vous.
							</li>
							<li>
								• Vous vivez une situation douloureuse et vous sentez dépassée.
							</li>
							<li>
								Vous êtes hypersensible ou les émotions vous submergent
								régulièrement.
							</li>
							<li>
								• Vous vous emportez régulièrement ou vous vous sentez blessée,
								rejetée, abandonnée et vous en souffrez terriblement.
							</li>
						</ul>
					</section>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Bénéfices de votre séance
						</h3>
						<br />
						<ul class='text-sm md:text-base leading-7 opacity-80'>
							<li>• Retrouver un confort émotionnel. </li>
							<li>
								• Vous reconnecter à vos besoins et développer votre confiance
								en vous.
							</li>
							<li>• Accueillir sereinement vos ressentis.</li>
							<li>
								• Apaiser les mémoires douloureuses et surmonter les étapes de
								votre vie.
							</li>
							<li>• Recontacter votre potentiel et votre pouvoir personnel.</li>
						</ul>
					</section>
				</div>
			</main>

			<Footer />
		</div>
	);
};

export default Liberation;
