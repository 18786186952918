import React from 'react';
// Router
import { Link } from 'react-router-dom';

// Components

const Footer = () => {
	return (
		<footer class='h-12 relative bottom-0 w-full bg-footer flex justify-around items-center text-xxs sm:text-sm p-6'>
			<Link to='/contact' class=''>
				Contact
			</Link>
			<Link to='/infos' class=''>
				Infos
			</Link>
			<Link to='/mentions-legales'>
				Mentions légales
			</Link>
		</footer>
	);
};

export default Footer;
