import React from 'react';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// images
import avatar from '../assets/img/marine.png';
import vinayak from '../assets/img/banniere.jpg';
import komori from '../assets/img/credit2.jpg';
import lee from '../assets/img/credit1.jpg';

// Composants
import Footer from '../Components/Footer';
const Infos = () => {
	return (
		<div class='min-h-screen flex flex-col justify-between items-center'>
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<section class='flex-1 w-full flex flex-col justify-center items-center pt-24'>
				<div class='h-60 w-60 mb-4'>
					<img class='rounded-full' src={avatar} alt='marine le pennec' />
				</div>
				<a
					href='https://portfolio-marine-lepennec.netlify.app/'
					target='_blank'
					rel='noreferrer'
					class='text-xl'>
					Marine <span class='text-yellow-500'>Le Pennec</span>
				</a>
				<p>Programmation et design</p>
			</section>
			<section class='w-full flex flex-col items-center justify-center mt-24'>
				<h2 class='text-2xl opacity-70 pb-6'>Photographies</h2>
				<div class='w-full flex flex-col md:flex-row items-center justify-center md:justify-around'>
					<div class='flex flex-col items-center justify-center mb-20'>
						<div class='h-60 w-60 mb-4'>
							<img
								class='h-full w-full object-center object-cover rounded-2xl'
								src={vinayak}
								alt='vinayak'
							/>
						</div>
						<a
							href='https://unsplash.com/@atulvi'
							target='_blank'
							rel='noreferrer'
							class='text-xl'>
							Atul
							<span class='text-yellow-500'> Vinayak</span>
						</a>
					</div>
					<div class='flex flex-col items-center justify-center mb-20'>
						<div class='h-60 w-60 mb-4'>
							<img
								class='h-full w-full object-center object-cover rounded-2xl'
								src={lee}
								alt='lee'
							/>
						</div>
						<a
							href='https://unsplash.com/@babybluecat'
							target='_blank'
							rel='noreferrer'
							class='text-xl'>
							J<span class='text-yellow-500'> Lee</span>
						</a>
					</div>
					<div class='flex flex-col items-center justify-center mb-20'>
						<div class='h-60 w-60 mb-4 '>
							<img
								class='h-full w-full object-center object-cover rounded-2xl'
								src={komori}
								alt='komori'
							/>
						</div>
						<a
							href='https://unsplash.com/@gaspanik'
							target='_blank'
							rel='noreferrer'
							class='text-xl'>
							Masaaki
							<span class='text-yellow-500'> Komori</span>
						</a>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Infos;
