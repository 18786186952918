import './App.css';

// Router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Pages
import Homepage from './Containers/Homepage';
import Aboutme from './Containers/Aboutme';
import Havening from './Containers/Havening';
import Liberation from './Containers/Liberation';
import Tarifs from './Containers/Tarifs';
import Contact from './Containers/Contact';
import Infos from './Containers/Infos';
import MentionsLegales from './Containers/MentionsLegales'

// Fontawsome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faHome,
	faEnvelope,
	faMobileAlt,
	faMapMarkerAlt,
	faAngleDoubleDown,
	faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
library.add(
	faHome,
	faEnvelope,
	faMobileAlt,
	faMapMarkerAlt,
	faAngleDoubleDown,
	faArrowLeft
);

function App() {
	return (
		<Router>
			<Switch>
				<Route path='/aboutme'>
					<Aboutme />
				</Route>
				<Route path='/tarifs'>
					<Tarifs />
				</Route>
				<Route path='/contact'>
					<Contact />
				</Route>
				<Route path='/havening'>
					<Havening />
				</Route>
				<Route path='/methode-liberation'>
					<Liberation />
				</Route>
				<Route path='/infos'>
					<Infos />
				</Route>
				<Route path='/mentions-legales'>
					<MentionsLegales />
				</Route>
				<Route exact path='/'>
					<Homepage />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
