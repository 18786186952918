import React from 'react';

// Image
import banniere from '../../assets/img/banniere.jpg';

const MainHeader = () => {
	return (
		<header class='h-60 w-full'>
			<img
				class='w-full h-full object-cover opacity-60'
				src={banniere}
				alt='bannière ginko'
			/>
		</header>
	);
};

export default MainHeader;
