import React from 'react';
// Images
import logo from '../assets/img/simple_logo.png';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import MainHeader from '../Components/headers/MainHeader';
import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

const Contact = () => {
	return (
		<div class='md:min-h-screen w-full relative flex flex-col items-center justify-between'>
			<MainHeader />
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<YellowHeader />
			<img
				src={logo}
				alt='logo'
				class='w-3/5 sm:w-3/5 xl:w-72 bottom-32 relative smd:w-60 opacity-80 '
			/>

			<p class='relative text-mainsubtitle text-lg opacity-60 text-center md:text-3xl bottom-24 xl:bottom-28'>
				POUR ME CONTACTER OU PRENDRE RENDEZ-VOUS
			</p>

			<section class='h-full w-full flex items-center justify-center md:pb-64 xl:pb-80'>
				<div class='h-full md:h-0 w-full flex flex-col md:flex-row items-center md:items-baseline justify-around '>
					<div class='w-full  flex flex-col items-center justify-center group md:text-2xl xxl:text-4xl mb-12'>
						<a
							href='mailto:laurence.constant@gmail.com'
							target='_blank'
							rel='noreferrer noopener'
							class='w-full flex flex-col items-center justify-center group md:text-xl xxl:text-4xl'>
							<FontAwesomeIcon
								icon='envelope'
								size='4x'
								color='rgba(225, 161, 0, 1)'
							/>

							<p class='md:hidden mt-4 opacity-60 md:group-hover:flex xxl:text-2xl'>
								laurence.constant@gmail.com
							</p>
						</a>
					</div>
					<div class='w-full flex flex-col items-center justify-center group md:text-2xl xxl:text-4xl mb-12'>
						<FontAwesomeIcon
							icon='mobile-alt'
							size='4x'
							color='rgba(225, 161, 0, 1)'
						/>

						<p class='md:hidden mt-4 opacity-60 md:group-hover:flex xxl:text-2xl'>
							07 64 42 28 25
						</p>
					</div>
					<div class='w-full flex flex-col items-center justify-center group md:text-xl xxl:text-4xl mb-12'>
						<FontAwesomeIcon
							icon='map-marker-alt'
							size='4x'
							color='rgba(225, 161, 0, 1)'
						/>

						<p class='md:hidden opacity-60 mt-4 md:group-hover:flex xxl:text-2xl'>
							{' '}
							Laurence Constant
							<br /> 4 rue des Palombes <br /> 64500 Ciboure
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Contact;
