import React from 'react';

// Router
import { Link } from 'react-router-dom';

const TechniqueSmall = () => {
	return (
		<h3 class=' md:hidden text-xl opacity-75 flex flex-col items-center justify-around'>
			Mes techniques
			<div class='mt-1 flex md:hidden flex-col items-center justify-between'>
				<Link to='/methode-liberation' class='text-sm mb-2 mt-1'>
					Méthode de libération des émotions
				</Link>
				<div class='w-11/12 h-0 border-2 border-yellow-500 border-opacity-75' />
				<Link to='/havening' class='text-sm mt-2'>
					The Havening
				</Link>
			</div>
		</h3>
	);
};

export default TechniqueSmall;
