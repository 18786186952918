import React from 'react';
// Components
import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tarifs = () => {
	return (
		<div class='flex flex-col justify-between h-screen'>
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<YellowHeader />
			<main class='flex flex-1 flex-col items-center'>
				<div class='w-full flex flex-col items-start z-0 p-6'>
					<div class='lg:h-1/4 w-fit xxl:w-2/5 bg-yellow-100 bg-opacity-75 flex items-start p-6 mt-16 lg:mt-24 lg:relative'>
						<h3 class='text-xl md:text-3xl opacity-80 xxl:leading-normal'>
							TARIFS
							<br /> ET PRESTATIONS
						</h3>
					</div>
					<div className='w-full p-6'>
					<div class='w-full bg-yellow-200 bg-opacity-80 flex flex-col items-align justify-around p-6'>
						<section>
							<div class='grid grid-cols-[80%_20%]'>
								<h4 class='text-lg'>
									Technique de Libération des Emotions <span class='opacity-70'>- 1h</span>
								</h4>
								<p class='w-16 text-right justify-self-end'>50 €</p>
							</div>
							<br />
							<div class='grid grid-cols-[80%_20%] mt-2'>
								<h4 class='text-lg'>Forfait 4 Séances</h4>
								<p class='w-16 text-right justify-self-end'>180 €</p>
							</div>
						</section>
						<div class='w-11/12 h-0 border-2 border-yellow-500 border-opacity-10 m-2' />
						<section>
							<div class='grid grid-cols-[80%_20%] mt-2'>
								<h3 class='text-lg'>
									Havening <span class='opacity-70'>- 1h</span>
								</h3>
								<p class='w-16 text-right justify-self-end'>50 €</p>
							</div>
							<br />
							<div class='grid grid-cols-[80%_20%] mt-2'>
								<h4 class='text-lg'>Forfait 4 Séances </h4>
								<p class='w-16 text-right justify-self-end'>180 €</p>
							</div>
						</section>
						<div class='w-11/12 h-0 border-2 border-yellow-500 border-opacity-10 m-2' />
						<section>
							<div class='grid grid-cols-[80%_20%] mt-2'>
								<h3 class='text-lg'>
									Pack Libération des Emotions
									<br /> & Havening <span class='opacity-70'>- 1h30</span>
								</h3>
								<p class='w-16 text-right justify-self-end'>80 €</p>
							</div>
							<br />
							<div class='grid grid-cols-[80%_20%] mt-2'>
								<h4 class='text-lg'>Forfait 4 Séances</h4>
								<p class='w-16 text-right justify-self-end'>300 €</p>
							</div>
						</section>
					</div>
					</div>
					<p class='w-full italic text-center opacity-60 box-border'>
						Possibilité d’effectuer ces rendez-vous par vidéo conférence.
					</p>
				</div>
			</main>

			<Footer />
		</div>
	);
};

export default Tarifs;
