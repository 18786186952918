import React from "react";

import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const MentionsLegales = ()=> {
return (
  <div class='flex flex-col justify-between h-screen'>
   <button
      type='button'
      onClick={() => {
        window.history.back();
      }}
      class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
      <FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
    </button>
		<YellowHeader />

    <main class='flex flex-col p-10'>
    <h2 className="mt-10 text-xl text-center my-6">MENTIONS LEGALES :</h2>

    <p>Correspondant aux directives  de l'article 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 relative à la Confiance dans l'économie numérique, dite L.C.E.N., nous informons les visiteurs du site : <a href="https://laurence-constant.fr">laurence-constant.fr</a> Ces infos suivantes :</p>
    <br />
    <p class='my-6'><strong>1. Données légales :</strong></p>

    <p>Nom du propriétaire : <strong>Laurence Constant</strong><br />
    Adresse postale du titulaire : <strong>4 rue des Palombes   64500 - Ciboure</strong><br />
    <br />
    Le Concepteur du site web  : <strong>Constant Laurence</strong><br />
    Décideur de la publication est : <strong>Constant Laurence</strong><br />
    Comment contacter le Chef de publication : <strong>laurence.constant@gmail.com</strong><br />
    <br />
    Le Concepteur est : <strong>Constant Laurence</strong><br />
        Comment joindre le Réalisateur : <strong><a href="mailto:laurence.constant@gmail.com?subject=Contact a partir des mentions légales via le site laurence-constant.fr">laurence.constant@gmail.com</a></strong><br />
    <br />

    Hebergeur : OVH<br />
    SAS au capital social de 10 174 560 Euros<br />
    Immatriculation RCS Lille Métropole 424 761 419 00045<br />
    Nomenclature APE 2620Z<br />
    Numero TVA : FR 22 424 761 419<br />
    Siège social : 2 rue Kellermann - 59100 Roubaix - France<br />
    <br />
    <br />
    Ces Mentions Légales ont été générées via <strong><a href="https://www.generateur-de-mentions-legales.com">le générateur de mentions légales</a></strong><br />
    </p>

    <p class='my-6'><strong>2. Introduction :</strong></p>
    <p>Tout internaute qui utilise le site : <a href="https://laurence-constant.fr">laurence-constant.fr</a> est considéré comme usageé du site suivant.<br />
    Le site suivant <strong>laurence-constant.fr</strong> réuni un ensemble de services, en l'état, disponible pour les utilisateur. Il est naturellement précisé que ces derniers doivent rester courtois et faire preuve de bonne foi tant envers les différents usagés qu'envers le Concepteur du site laurence-constant.fr. Le site laurence-constant.fr est actualisé régulièrement par Constant Laurence.<br />
    Constant Laurence s'efforce de fournir sur le site laurence-constant.fr des informations les plus véridiques possibles (sous réserve de modifications), mais ne saurait attester l'exactitude, la complétude et les informations répandu sur son site internet, qu'elles soient ou non de son fait. En conséquence, l'utilisateur reconnaît utiliser ces informations fournies  sous son unique responsabilité.</p>

    <p class='my-6'><strong>3. Accessibilité :</strong><br />
    <br />
    Le site suivant laurence-constant.fr est généralement accessible aux utilisateurs 24/24h, 7/7j, excepté en cas d'interruption, prévu ou non, pour les besoins de la maintenance ou en cas de force majeure. En cas de service indisponible, laurence-constant.fr s'engage à faire de son mieux afin de rétablir l'accès au site internet et s'efforcera alors d'informer au préalable aux utilisateurs Dates & heures de l'intervention. N'étant soumis qu'à une obligation de moyen, laurence-constant.fr ne saurait être designé pour responsable des soucis, quelle qu'en soit la nature.</p>

    <p class='my-6'><strong>4. Propriété intellectuelle :</strong><br />
    <br />
    Constant Laurence est le propriétaire unique de l'intégralité des droits de propriété intellectuelle ou détient les droits et autorisations d'usage sur l'intégralité des éléments accessibles sur le site, tant sur la structure que sur les textes, images, graphismes, icônes, sons, logiciels…<br />
    Toute reproduction même partielle du site suivant laurence-constant.fr, affichage, modification, mis à jour totale ou partielle de l'un quelconque de ces éléments, peu importe le  moyen ou la technique utilisé, est interdite, sauf acceptation  préalable de Constant Laurence, responsable du site web à l'email : laurence.constant@gmail.com, à défaut elle sera considérée comme constitutive d'une contrefaçon et passible de poursuite conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
    Toute reproduction totale ou partielle du affichage, modification, mis à jour totale ou partielle de l'un quelconque de ces éléments, peu importe le  moyen ou le procédé utilisé, est interdite, excepté acceptation  préalable de Constant Laurence, propriétaire du site suivant au mail suivant : laurence.constant@gmail.com, à défaut elle sera désigné de la même manière qu'une contrefaçon et passible de poursuite conformément aux dispositions des multiples articles L.335-2 et suivants du Code de Propriété Intellectuelle.
    </p>

    <strong class='my-6'>5. Cookies & Liens Hypertextes :</strong><br />
    <br/>
    <p>
    Le site internet laurence-constant.fr possède un certain nombre de liens hypertextes vers d'autres sites web (partenaires, sites sources etc) mis en ligne suite à l'accord de Constant Laurence<a href="https://www.generateur-de-mentions-legales.com/" style={{textDecoration:'none'}}><font color="#000000">.</font></a> Néanmoins, Constant Laurence n'a pas la possibilité de vérifier l'ensemble des élements des différents sites  visités et décline donc toute responsabilité de de cette façon quand aux eventuels risques de contenus illicites.<br />
    L'usager est averti que lors de ses navigation sur le site suivant laurence-constant.fr, un ou plusieurs cookies sont capable de s'installer mécaniquement sur son pc via son logiciel de navigation. Un cookie est un assemblage de donnée qui ne permettra pas de savoir qui est l'utilisateur, cependant qui enregistre des indications relatives à la navigation de l'utilisateur sur le site suivant. <br />
    La configration du programme de navigation tolère d'informer de la compagnie de cookie et potentiellement, de pouvoir la contester de la procédure expliquée à l' adresse  : <a href="https://www.laurence-constant.fr">www.laurence-constant.fr</a>. La mise en place à la possibilité néanmoins de configurer le navigateur de son pc afin de refuser l'installation des cookies, étant conscient que le rebut de la mise en place d'un cookie pourrait  entraîner l'incapacité d'avoir accès à certains secteurs. Concernant tout blocage des cookies, recherchez dans le outil de recherche : blocage des cookies et observez les instructions en lien avec votre version de navigateur.
    </p>

    <strong class='my-6'>6. Protection des personnes et des biens - Maniement des données personnelles :</strong><br />
    <br />
    En France, les données personnelles sont par exemple protégées par la loi n° 78-87 du 6 janvier 1978 ainsi que la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne datant du 24 octobre 1995.

    <p>Sur le site laurence-constant.fr, Constant Laurence ne aucune information personnelles ( suivant l'article 4 loi n°78-17 du 06 janvier 1978) concernant l'usager que pour la nécéssité de plusieurs services mis à disposition par le site laurence-constant.fr. L'usager fournit les données en toute conscience de cause, notamment lorsqu'il procède de lui-même à la saisie. Il est donc précisé à l'usagé du site laurence-constant.fr l'obligation ou pas de rentrer ces données.<br />
    En conformité avec les agencements des articles 38 et autres de la loi 78-17 du 6 janvier 1978 relative à l'informatique, les fichiers et aux différentes libertés, tout utilisateur possède un droit d'accès aux informations, de rectification, de suppression et d'opposition aux informations personnelles qui le concerne. Pour l'exercer, envoyez une demande à laurence-constant.fr par email : <strong><a href="mailto:laurence.constant@gmail.com?subject=Contact à partir des mentions légales via le site laurence-constant.fr">laurence.constant@gmail.com</a></strong> ou par écrit  signée, accompagnée d'une copie de pièce d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.</p>

    <p>Aucune information personnelle de l'usager du site suivant laurence-constant.fr n'est publiée à l'insu de l'utilisateur, troquée, transmise, cédée sur n'importe quel support à des tiers. Seule l'hypothèse de vente du site web laurence-constant.fr et de tout ses droits autorise Constant Laurence à céder les dites informations personnelles à l'éventuel acquéreur qui serait  lui-même tenu à la même nécéssité de conservation et de modification des données concernant l'utilisateur du site web laurence-constant.fr.<br />
    Le site interne laurence-constant.fr est conforme avec le RGPD consulter notre politique RGPD.</p>

    <p>Les bases de données sont protégées par les règles de la loi du 1er juillet 1998 transposant ainsi la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
    </main>
    <Footer />
  </div>
)
}

export default MentionsLegales
