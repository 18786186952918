import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Router
import { Link } from 'react-router-dom';

// Components
import MainHeader from '../Components/headers/MainHeader';
import TechniqueSmall from '../Components/Homepage/TechniqueSmall';
import TechniqueWide from '../Components/Homepage/TechniqueWide';
import Footer from '../Components/Footer';

// Images
import logo from '../assets/img/logo.png';
import citation from '../assets/img/citation.png';
import portrait from '../assets/img/portrait.jpg';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Homepage = () => {
	useEffect(() => {
		AOS.init({
			easing: 'ease-out-back',
			duration: 1000,
		});
	}, []);

	return (
		<div class='w-full relative flex flex-col items-center justify-center '>
			<div class=' h-full w-full relative flex flex-col items-center justify-center '>
				<MainHeader />
				<div class='absolute w-full flex justify-center top-4 smd:top-3 h-2/5 xl:h-2/5'>
					<img src={logo} alt='logo' class='h-full opacity-80' />
				</div>

				<section class='h-full w-full flex flex-col justify-center md:justify-around items-center md:relative'>
					<p class='text-grey-900 opacity-75 text-center mt-20 mb-10 sm:hidden p-4'>
						PRATICIENNE EN KINESIOLOGIE <br /> MÉTHODE DE LIBERATION DES
						ÉMOTIONS
						<br /> HAVENING
					</p>
					<p class='hidden sm:flex w-full text-mainsubtitle opacity-75 text-xl mt-40 md:mt-52 mlg:mt-80 xl:mt-40 mlg:text-2xl text-center md:justify-center'>
						PRATICIENNE EN KINESIOLOGIE - MÉTHODE DE LIBERATION DES ÉMOTIONS -
						HAVENING
					</p>
					<img
						src={citation}
						alt='citation'
						class='hidden md:flex md:w-96 mlg:w-2/4 xl:w-1/4 mt-24 '
					/>

					<section
						class='h-60 sm:h-56 md:h-14 w-10/12 md:w-11/12	bg-yellow-300 bg-opacity-75 rounded-md flex md:flex-row flex-col items-center justify-around sm:mt-16 md:mt-36 mlg:mb-16 mlg:mt-60 xl:mt-20 xl:mb-4
				'>
						<Link to='/aboutme' class='text-xl md:text-center opacity-75'>
							Qui suis-je ?
						</Link>
						<TechniqueSmall />
						<TechniqueWide />

						<Link to='/tarifs' class='text-xl  md:text-center opacity-75'>
							Tarifs et prestations
						</Link>
						<Link to='/contact' class='text-xl  md:text-center opacity-75'>
							Prendre rendez-vous
						</Link>
					</section>
				</section>
				<a href='#main-infos' class=' text-5xl'>
					<FontAwesomeIcon
						href='#main-infos'
						icon='angle-double-down'
						size='5xl'
						color='rgba(243, 179, 13, 0.56)'
					/>
				</a>
			</div>
			<section
				id='main-infos'
				class=' h-full {screen}:w-full flex flex-col items-center justify-start pt-20 pl-8 pr-8 xl:pl-12 xl:pr-12'>
				<div class=' w-full h-1/3  sm:flex flex-col items-center justify-between box-border'>
					<div
						class='w-3/5 mlg:w-96 xxxl:w-80 h-full mb-8 sm:mr-8 rounded-full'
						data-aos='fade-up'>
						<img
							class='h-full w-full rounded-full'
							src={portrait}
							alt='Laurence Constant'
						/>
					</div>
					<div class='{screen}:w-4/5 h-full box-border flex relative xl:pl-20'>
						<div
							class='h-fit sm:w-11/12 sm:h-3/4 mlg:h-4/6 bg-yellow-200 bg-opacity-75 z-10 p-8 flex items-center'
							data-aos='fade-up'
							data-aos-offset='200'
							data-aos-delay='100'>
							<p class=' text-xs sm:text-lg sm:leading-7 mlg:leading-9 xl:text-2xl xl:tracking-wider xl:leading-10 opacity-70'>
								J’ai exploré de nombreuses approches thérapeutiques qui me
								permettent aujourd’hui de vous accompagner dans votre évolution
								personnelle.
							</p>
						</div>
						<div
							class='w-11/12 h-20 z-10 sm:h-32 bg-yellow-100 absolute bg-opacity-50 top-32 sd:top-28 smd:top-28 sm:top-36 md:top-22 xxxl:left-36 -right-4 xl:left-28 flex items-center justify-center pb-0'
							data-aos='fade-up'
							data-aos-offset='200'
							data-aos-delay='300'>
							<Link
								to='/aboutme'
								target='_blank'
								class='text-lg sm:text-2xl tracking-wider italic opacity-50'>
								En savoir plus
							</Link>
						</div>
					</div>
				</div>
				<p class='text-xl text-center mlg:text-2xl lg:text-3xl italic pt-32 sd:pt-40 sm:pt-32 xl:pt-60 opacity-70'>
					<q>Qui regarde dehors rêve. Qui regarde à l'interieur se réveille.</q>
					<br />
					<span>C.G Jung</span>
				</p>

				<div class='w-full h-full flex flex-col items-center justify-between mt-16 mlg:mt-4 mlg:pt-16 pb-24'>
					<h3
						class='text-2xl lg:text-3xl sm:pt-8 sm:pb-12 mmd:pb-8 mxlg:pb-0 opacity-80'
						id='trigger-methodes'>
						De quelles manières ?
					</h3>
					<div
						class='w-full h-full flex flex-col items-center md:items-end md:flex-row justify-around mt-8'
						data-aos='fade-up'
						data-aos-offset='200'
						data-aos-delay='100'
						data-aos-anchor='#trigger-methodes'
						data-aos-anchor-placement='bottom'>
						<div class='flex-1 mr-2 w-full md:w-2/6 h-32 mlg:h-48 flex flex-col items-center justify-around group p-3'>
							<h4 class='text-sm sm:text-base text-yellow-500 text-center mlg:text-xl xl:text-3xl opacity-70 '>
								Méthode
								<br /> de libération des émotions
							</h4>
							<div class='w-8/12 h-0 border-2 border-yellow-900 border-opacity-20' />
							<Link
								to='/methode-liberation'
								target='_blank'
								class='text-xs sm:text-ml mlg:text-lg xl:text-2xl italic opacity-0 group-hover:opacity-70 transition-opacity duration-700'>
								En savoir plus
							</Link>
						</div>
						<div
							class='flex-1 ml-2 w-full md:w-2/6 h-32 mlg:h-48 flex flex-col items-center justify-around group p-4'
							target='_blank'>
							<h4 class='text-sm text-center text-yellow-500 sm:text-xl mlg:text-2xl xl:text-3xl opacity-70'>
								The Havening
							</h4>
							<div class='w-8/12 h-0 border-2 border-yellow-900 border-opacity-20 md:mt-11' />
							<Link
								to='/havening'
								target='_blank'
								class='text-xs sm:text-ml mlg:text-lg xl:text-2xl italic opacity-0 group-hover:opacity-70 transition-opacity duration-700'>
								En savoir plus
							</Link>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Homepage;
