import React from 'react';
// Router
import { Link } from 'react-router-dom';

const TechniqueWide = () => {
	return (
		<div class='hidden w-40 group relative md:inline-block md:justify-center'>
			<h3 class='hidden w-full md:flex text-xl opacity-75 justify-center cursor-default'>
				Mes techniques
			</h3>
			<div class='hidden group-hover:flex z-10 absolute h-32 bg-yellow-100 flex-col items-center justify-center text-center bg-opacity-80'>
				<Link to='/methode-liberation' class='text-sm mb-2 mt-1 z-10'>
					Méthode de libération des émotions
				</Link>
				<div class='w-11/12 h-0 border-2 border-yellow-500 border-opacity-50  m-2' />
				<Link to='/havening' class='text-sm mt-2'>
					The Havening
				</Link>
			</div>
		</div>
	);
};

export default TechniqueWide;
