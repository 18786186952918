import React from 'react';

import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

// Images
import portrait from '../assets/img/portrait.jpg';
// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Aboutme = () => {
	return (
		<div class='w-full'>
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<YellowHeader />
			<main class='h-full w-full md:p-3 lg:pl-40 lg:pr-40'>
				<div class='w-full bg-about-backg bg-center bg-opacity-30 flex flex-col items-center justify-start pt-20 lg:pl-40 lg:pr-40 md:pt-0 md:mt-40'>
					{/* Circle */}
					<div className='w-40 md:w-60 h-40 md:h-60 rounded-full md:absolute md:top-20'>
						<img
							src={portrait}
							alt='laurence constant'
							class='h-full w-full rounded-full'
						/>
					</div>
					{/* All presentation */}
					<div class='p-6 md:mt-40'>
						<h2 class='opacity-80 md:text-xl lg:text-2xl'>
							Bienvenue sur mon site,
						</h2>
						<section class='w-full flex flex-col items-center lg:mb-32'>
							<br />
							<p class='text-sm md:text-base lg:text-xl opacity-75 leading-7'>
								Naturopathe de formation, j’ai exploré de nombreuses approches
								thérapeutiques qui me permettent aujourd’hui de vous accompagner
								dans votre évolution personnelle. Mon souhait le plus profond
								est de vous aider à accéder à un mieux-être durable de manière
								respectueuse et attentionnée. <br /> <br />
								Les méthodes que j’utilise agissent sur les plans physique,
								mental et psycho-émotionnel. Nos blocages émotionnels sont
								souvent issus de situations anciennes qui nous ont bouleversés
								ou sidérés et nos émotions n’ont pas pu trouver de dénouement
								heureux.
								<br />
								<br />
								<br />
							</p>
							<p class='text-sm md:text-base lg:text-xl italic'>
								<q>
									Qui regarde dehors rêve. Qui regarde à l'interieur se
									réveille.
								</q>
								<br />
								<span>C.G Jung</span>
							</p>
						</section>
						<section class='mt-12 flex flex-col items-center'>
							<h3 class='text-center text-lg md:text-2xl'>
								De quelle manière ?
							</h3>
							<div class='md:w-9/12 md:h-52 lg:h-72 bg-yellow-200 p-3 rounded-md mt-6 flex flex-col items-center md:justify-around lg:justify-center'>
								<h4 class='text-center md:text-xl lg:text-2xl'>
									Méthode de libération des émotions
								</h4>
								<div class='w-9/12 h-0 border border-yellow-900 border-opacity-30 mt-4 mb-4 md:mt-0 md:mb-0 lg:mt-8 lg:mb-9' />
								<p class='text-sm md:text-base opacity-90 lg:pl-24 lg:text-lg'>
									Pour mettre en lumière les blocages et émotions cristallisés
									dans le corps. <br />
									Une fois conscientisés, je vous amène alors à les libérer
									pendant la séance, à votre rythme, en toute sécurité.
								</p>
							</div>
							<div class='md:w-9/12 md:h-52 lg:h-72 bg-yellow-200 p-3 rounded-md mt-6 flex flex-col items-center md:justify-around lg:justify-center'>
								<h4 class='text-center md:text-xl lg:text-2xl'>Le Havening</h4>
								<div class='w-9/12 h-0 border border-yellow-900 border-opacity-30 mt-4 mb-4 md:mt-0 md:mb-0 lg:mt-8 lg:mb-9 ' />
								<p class='text-sm md:text-base opacity-90 lg:pl-24 lg:text-lg'>
									Pour travailler sur la libération des peurs, des phobies et
									des traumatismes vécus.
									<br /> Cette approche est également précieuse pour aider à
									dépasser les colères et les émotions négatives qui vous
									entravent.
								</p>
							</div>
						</section>

						<section class='pt-6'>
							<p class='text-sm md:text-base lg:text-xl leading-6 font-bold;'>
								Cette synergie me permet de vous proposer un accompagnement
								solide et de courte durée, en 3 ou 4 séances progressives
								espacées d’environ un mois. Ce rythme respectueux permet à votre
								corps d’intégrer les phases de libération au fur et à mesure
								afin de retrouver un nouvel équilibre. Bien sûr, il est
								impossible de faire une séance isolée sur une problématique
								précise, à choisir alors entre les deux approches ci-dessus.
							</p>
						</section>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default Aboutme;
