import React from 'react';

// Router
import { Link } from 'react-router-dom';

// Components
import TechniqueWide from '../Homepage/TechniqueWide';
// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MiniHeader = () => {
	return (
		<div class='hidden z-10 top-0 md:h-7 p-6 lg:h-12 w-full absolute md:flex md:justify-around items-center bg-yellow-400 bg-opacity-70'>
			<Link to='/'>
				<FontAwesomeIcon icon='home' size='lg' color='rgba(0, 0, 0, 0.70)' />
			</Link>
			<Link to='/aboutme' class='text-xl opacity-70'>
				Qui suis-je
			</Link>
			<TechniqueWide />
			<Link to='/tarifs' class='text-xl opacity-70'>
				Tarifs et prestations
			</Link>
			<Link to='/contact' class='text-xl opacity-70'>
				Prendre rendez-vous
			</Link>
		</div>
	);
};

export default MiniHeader;
