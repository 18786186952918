import React from 'react';

import YellowHeader from '../Components/headers/YellowHeader';
import Footer from '../Components/Footer';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Havening = () => {
	return (
		<div class='flex flex-col justify-between'>
			<button
				type='button'
				onClick={() => {
					window.history.back();
				}}
				class='z-10 absolute top-3 left-3 opacity-60 md:hidden'>
				<FontAwesomeIcon icon='arrow-left' size='2x' color='black' />
			</button>
			<YellowHeader />
			<main class='flex flex-col items-center'>
				<section class='h-1/5 lg:h-40 md:h-40 w-full flex justify-start relative top-4 mt-16 lg:mt-24 pl-4 box-border'>
					<div class='h-full w-6/12 bg-yellow-400 bg-opacity-75 flex text-start pl-4 items-center'>
						<h2 class='text-xl md:text-5xl opacity-80 xxl:leading-normal'>
							THE
							<br />
							HAVENING
						</h2>
					</div>
				</section>
				{/* Contenant avec bg */}
				<div class='z-1 w-11/12 p-4 mb-2 lg:w-8/12 bg-tech-backg bg-right-top right-2 lg:right-24 xxl:left-80 top-28 md:top-48 flex flex-col justify-evenly md:items-center'>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Mon cadre Thérapeutique{' '}
						</h3>
						<br />
						<p class='text-sm md:text-base leading-7 opacity-80'>
							Qu’est-ce que le HAVENING ? La Technique de Havening est une
							nouvelle méthode thérapeutique puissante qui va vous aider à
							surmonter les problèmes émotionnels anciens
						</p>
					</section>
					<section class=' md:w-4/5 bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Comment se déroule une séance ?
						</h3>
						<br />
						<p class='text-sm md:text-base leading-7 opacity-80  xxl:pl-0'>
							Par un toucher doux et rassurant qui va créer des changements
							biologiques dans le cerveau et favoriser la résilience. Il faut
							savoir que la mémoire d’un événement est séparée de la détresse
							qu’elle provoque. Ainsi, après votre séance de libération,
							l’événement peut revenir à la mémoire mais son emprise
							émotionnelle aura disparu Les études cliniques montrent que les
							réactions de détresse sont réduites à des niveaux insignifiants
							après l’utilisation de cette méthode.
						</p>
					</section>
					<section class=' md:w-4/5  bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Cette séance est pour vous si
						</h3>
						<br />
						<ul class='text-sm md:text-base leading-8 opacity-80'>
							<li>
								• Vous avez vécu un traumatisme et en subissez encore les
								effets.
							</li>
							<li>• Vous souffrez de phobies ou de peurs irraisonnées.</li>
							<li>• Vous souffrez d’anxiété chronique.</li>

							<li>• Vous vivez des crises de paniques incontrôlables.</li>
							<li>
								• Et surtout quand une situation douloureuse est perçue comme
								inéluctable et insurmontable.
							</li>
						</ul>
					</section>
					<section class=' md:w-4/5  bg-yellow-200 p-3 mt-4 md:mt-12 rounded-md flex flex-col items-start'>
						<h3 class='md:text-xl w-full text-center opacity-70'>
							Bénéfices de votre séance
						</h3>
						<br />
						<p class='text-sm md:text-base leading-7 opacity-80'>
							<li>
								• Guérir les séquelles émotionnelles et psychologiques laissées
								par les expériences traumatiques.
							</li>
							<li>
								• Réduire la réactivité émotionnelle tout en élargissant et en
								renforçant la capacité de réagir et de s’adapter aux défis et au
								changement.
							</li>
							<li>• Soulager l’inconfort émotionnel du moment présent.</li>
							<li>
								• Réduire et éliminer les racines émotionnelles de la douleur
								chronique.
							</li>
						</p>
					</section>
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default Havening;
